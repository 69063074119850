import React from 'react'
import { Row, Col, Button, Card } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from "react-redux";

import { FormatoCantidad, logoImg, logoSeguro } from '../helpers/functionGlobal';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { useNavigate } from 'react-router-dom';

const PlanCotizacionCliente = (props) => {

    const { closeModal, iniciarSolicitud } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const obtencionPutCotiza = useSelector((state) => state.putCotiza);
    const { Codigo, IdCotizacionSeguro, Pagomensual, ValorAuto, Enganche, financing_amount, commission, 
            insurance_amount, ComisionPorc, Plazo, ImporteSeguro, ImporteOtrosSeguros} = obtencionPutCotiza.putCotiza;
    const { Logo, Nombre, bottonText, PagoMensual, Financiamiento_str, Pagomensual_str,
        ValorAuto_str, Enganche_str } = obtencionPutCotiza.putCotiza.Cotizacion;
    const obtencionCotizador = useSelector((state) => state.cotizador);
    const { Aseguradora } = obtencionCotizador.seguroSelect;
    const { SeguroFinanciado } = obtencionCotizador.cotizacion;

    const mensajeDocumentos = () => {
        dispatch(showAlert(MensajesAlertas.mensajeDocumentosMano, () => { closeModal();  navigate("/solicitud") }, closeModal));
    }

    const tipoSeguro = (codigo, IdCotizacionSeguro, seguroFinanciado) => {
        let textoTipoSeguro = "";
        if(codigo === 0 && IdCotizacionSeguro === null) {
            textoTipoSeguro = "no incluido";
        } else if(seguroFinanciado) {
            textoTipoSeguro = "Financiado";
        } else {
            textoTipoSeguro = "de Contado";
        }
        return textoTipoSeguro;
    }

    return (
        <Card className='card-plan-cotizacion'>
            <Card.Body >
                <p className="texto-info-calculo">Cálculo informativo, puede variar acorde a la información e historial crediticio del cliente</p>
                <p className='tipo-pago'>Mensualidad</p>
                <p className='monto'>{Pagomensual_str}</p>
                <p className='mensualidades'>a {Plazo} meses</p>
                <Row>
                    <Col xs={12} className="mb-3">
                        <p className='texto-label'>Financiado por:</p>
                        <p className='texto-resultado'><img src={Logo} className="ms-2 logo-seguro" alt={Aseguradora}  /></p>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <p className='texto-label'>Costo del auto</p>
                        <p className='texto-resultado'>{ValorAuto_str}</p>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <p className='texto-label'>Enganche</p>
                        <p className='texto-resultado'>{Enganche_str}</p>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <p className='texto-label'>Monto a financiar</p>
                        <p className='texto-resultado'>{Financiamiento_str}</p>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <p className='texto-label'>Comisión por apertura</p>
                        <p className='texto-resultado'>${FormatoCantidad(commission, 2, ".", ",")}</p>
                    </Col>
                    <Col xs={12} >
                        <p className='texto-label'>
                            Seguro { tipoSeguro(Codigo, IdCotizacionSeguro, SeguroFinanciado) }
                            {
                                //Codigo === 0 && IdCotizacionSeguro !== null &&
                                IdCotizacionSeguro !== null &&
                                <img src={logoImg(`./${logoSeguro(Aseguradora)}`)} className="ms-2 logo-seguro" alt={Aseguradora}  />
                            }
                        </p>
                        {
                            //Codigo === 0 && IdCotizacionSeguro !== null && <p className='texto-resultado'>${FormatoCantidad(insurance_amount, 2, ".", ",")}</p>
                        }
                        
                    </Col>
                    {
                        Codigo === 0 && IdCotizacionSeguro !== null &&

                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Costo seguro</p>
                            <p className='texto-resultado'>${FormatoCantidad(ImporteSeguro, 2, ".", ",")}</p>
                        </Col>
                    }
                    
                    {
                        Nombre === "CIBANCO" && 
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Otros seguros</p>
                            <p className='texto-resultado'>${FormatoCantidad(ImporteOtrosSeguros, 2, ".", ",")}</p>
                        </Col>
                    }
                    {
                        bottonText !== '' && <p className='text-danger text-center m-0 mt-3'><b>{bottonText}</b></p>
                    }
                </Row>
            </Card.Body>
            <Card.Footer>
                <Button type="submit" className="btn btn-primary w-100" onClick={() => mensajeDocumentos()}>Llenar solicitud</Button>
            </Card.Footer>
        </Card>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        iniciarSolicitud: () => {}
	};
};
//export default PlanCotizacionCliente
export default connect(null, mapDispatchToProps)(PlanCotizacionCliente)